import React, { useEffect, useState } from 'react';
import {Link} from 'react-router-dom'
import {Routes, Route, Navigate} from 'react-router-dom'
import './App.css';
import lens from './img/lens.png'
import astronaut from './img/astronaut-helmet.svg'
import telescope from './img/telescope.svg'

var arrayitems = ['mix','forum','book','comic magazine','movie','game','music'];
  
export default function Main(){
 
  return (
    <>
      <div>
        <nav className='topbar'>
          <Addtopwidget href="#top" src={lens} imgclassName='lens' width='50' className="upb"/>    
          <Addtopwidget href="#" src={astronaut} imgclassName='account' width='25'/>    
          <Addtopwidget href="#" src={telescope} imgclassName='research' width='25'/>
        </nav>
      </div>

      <div className='content'>
        <p className='zain-regular' id='top'>intertidal</p>
        <div className='intertidal'>
          <div className='intertidal-list'>
            <Addwebsite/>
            <a className='buttonadd buttonstyle' onClick={()=>alert('请登录')} href='#'></a>
          </div>
        </div>
      </div>
      
      <div className='container'>
        <div className='maincontainer'>
          <Addmaincontainer/>
        </div>
        <div className='selectcontainer'>
          <Addselectcontainer/>
        </div>
      </div>

      <div className="fg">
      </div>
    </>
  );
}

function Addmaincontainer(){

}

function Addwebsite(){

  const [website,setwebsite] = useState([]);
  
  function Widget({websites}){
    
    useEffect(()=>{
      fetch('/getwebsitedata')
      .then(response=>response.json())
      .then(data=>setwebsite(data))
      .catch(error=>console.error("Error fetching data:",error))
    },[]);
    
    return(
      <></>
    );
  }
        
  return(
    <>
      <Routes>
        {arrayitems.map(
          (item,index)=>(<Route path={'/'+item} element={<Widget websites={index}/>}/>)
        )}
        <Route path='' element={<Navigate to='mix'/>}/>
      </Routes>
    </>
  );
}

function Addselectcontainer(){

  const [selecttype,setselecttype] = useState(0);

  return(
    <ul className='selectlist'>
      {arrayitems.map(
        (item,index)=>(<li className='listtype'><Link to={item} onClick={()=>{setselecttype(index)}} style={{
            fontSize:selecttype === index ? '140%' : '110%'
        }}>{item}</Link></li>)
      )}
    </ul>
  );
}

function Addtopwidget({className,href,src,imgclassName,width}){
    return(
        <a className={className} href={href}>
      <img className={imgclassName} src={src} width={width} alt='loading'/>
    </a>
  );
}