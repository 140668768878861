import Main from './mainpage.js'
import {BrowserRouter as Router,Routes,Route,Navigate} from 'react-router-dom'

export default function App() {
  return(
    <Router>
      <Routes>
        <Route path='' element={<Navigate to='home'/>}/>
        <Route path='/home/*' element={<Main/>}/>
        <Route path='*' element={<div>404</div>}/>
      </Routes>
    </Router>
  );
}


